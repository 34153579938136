import React from 'react';

import Layout from '../components/Layout';
import images from '../masjid_images';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>New Property Gallery</h2>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <section className="features">
            {images.map(img => (
              <article>
                <a
                  onClick={() =>
                    window.open(img)
                  }
                  className="image"
                >
                  <img src={img} alt="" />
                </a>
              </article>
            ))}
          </section>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;

import p1 from './assets/images/masjid/1.jpeg';
import p2 from './assets/images/masjid/2.jpeg';
import p3 from './assets/images/masjid/3.jpeg';
import p4 from './assets/images/masjid/4.jpeg';
import p5 from './assets/images/masjid/5.jpeg';
import p6 from './assets/images/masjid/6.jpeg';
import p7 from './assets/images/masjid/7.jpeg';
import p8 from './assets/images/masjid/8.jpeg';
import p9 from './assets/images/masjid/9.jpeg';
import p10 from './assets/images/masjid/10.jpeg';
import p11 from './assets/images/masjid/11.jpeg';
import p12 from './assets/images/masjid/12.jpeg';
import p13 from './assets/images/masjid/13.jpeg';
import p14 from './assets/images/masjid/14.jpeg';
import p15 from './assets/images/masjid/15.jpeg';
import p16 from './assets/images/masjid/16.jpeg';
import p17 from './assets/images/masjid/17.jpeg';
import p18 from './assets/images/masjid/18.jpeg';
import p19 from './assets/images/masjid/19.jpeg';
import p20 from './assets/images/masjid/20.jpeg';

export default [
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  p8,
  p9,
  p10,
  p11,
  p12,
  p13,
  p14,
  p15,
  p16,
  p17,
  p18,
  p19,
  p20,
];
